@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .job-description ol {
    list-style: auto;
    padding-inline-start: 40px
  }

  .job-description p {
    @apply py-2 text-gray-700
  }
}
